<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles/individual</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      sticky-header
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(subject)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    permissionsData: {
      required: true,
    },
  },
  
}
</script>

<style>

</style>
